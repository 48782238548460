<template>
  <div>
    <strong class="my-2">{{name}}</strong>
    <div :id="id" :style="`width: ${width}; height: ${height};`"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { v4 as uuid } from 'uuid'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['data', 'name', 'width', 'height'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    load: async function () {
      const option = {
        series: [
          {
            type: 'treemap',
            data: this.data,
            breadcrumb: {
              show: false
            }
          }
        ]
      }
      await delay(10)
      echarts.init(document.getElementById(this.id), 'kpmg').setOption(option)
    },
    reload: function () {
      console.log('triggered reload')
      echarts.dispose(document.getElementById(this.id))
      this.load()
    }
  },
  watch: {
    'nodes': 'reload',
    'links': 'reload',
    'categories': 'reload',
    'layout': 'reload',
    'name': 'reload'
  }
}
</script>

<style>
</style>
