<template>
<div>
  <b-container fluid class="bg-white">
    <b-row class="py-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Reporting Frameworks</h1>
        <p>Explore reporting frameworks. For each framework you can see a high level breakdown of metrics by pillar. Click on a framework name to explore its metrics and data elements.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="mb-4" style="display: flex; height: 30px;">
          <sort-interface class="mr-4" namespace="dataelements" :rows="rows" v-model="rowsSorted" :optionsProperty="sortProperties"></sort-interface>
          <search-interface namespace="dataelements" :rows="rowsSorted" v-model="rowsSearched"></search-interface>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
        <div class="mb-3" v-for="item in showRowsPage" :key="item.id">
          <line-item :item="item" :trim="400"></line-item>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import LineItem from '@/views/metrics/ReportingframeworkSimple.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'
import SortInterface from '@/components/SortInterfaceV1.vue'

function countUniqueValues (array) {
  // Count the occurrences of each value
  const counts = _.countBy(array)
  // Transform the counts object into an array of objects with name and count properties
  const result = _.map(counts, (value, name) => ({ name, value }))
  return result
}

export default {
  name: 'Reportingframeworks',
  components: {
    LineItem,
    SearchInterface,
    SortInterface
  },
  computed: {
    metricsAll: function () {
      return this.$store.state.metrics
    },
    rows: function () {
      const frameworks = this.$store.state.reportingframeworks
      frameworks.forEach(framework => {
        let metrics = this.metricsAll.filter(metric => metric.reportingframeworks.find(f => f.id === framework.id))
        metrics = _.uniqBy(metrics, 'id')
        const pillars = []
        metrics.forEach(metric => {
          metric.taxonomy.forEach(taxonomy => {
            taxonomy.parents.forEach(parent => {
              pillars.push(parent.name)
            })
          })
        })
        framework.pillars = countUniqueValues(pillars)
        framework.pillars.forEach(pillar => {
          framework[pillar.name] = pillar.count
        })
        framework.metrics = metrics.length
      })
      return frameworks
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rowsSearched.slice(start, end)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      rowsSearched: [],
      rowsSorted: [],
      sortProperties: [
        { value: 'name', text: 'Name' },
        { value: 'metrics', text: 'Unique metrics' },
        { value: 'Environmental', text: 'Environmental' },
        { value: 'Social', text: 'Social' },
        { value: 'Governance', text: 'Governance' }
      ],
      staticDataProperties: [
      ]
    }
  },
  created: function () {
    document.title = "ESG Metrics"
  }
}
</script>

<style>
</style>
