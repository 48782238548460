<template>
  <b-card class="mb-3">
    <b-container fluid>
      <b-row>
        <b-col>
          <router-link :to="{ name: 'MetricsReportingframework', params: { id: item.id }}"><h5 class="card-title">{{item.name}}</h5></router-link>
          <div>
            <strong>Unique metrics:</strong> {{ item.metrics }} |
            <span v-for="pillar in item.pillars" :key="pillar.name + item.id">
              <strong>{{ pillar.name }}:</strong> {{ pillar.value }} |
            </span>
          </div>
          <div>
            {{ item.definition }}
          </div>
        </b-col>
        <b-col>
          <treemap-simple :data="item.pillars" :name="''" :width="'100%'" :height="'60px'"></treemap-simple>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import TreemapSimple from '@/components/charts/v1/treemapSimple.vue'
export default {
  name: 'ReportingframeworkSimple',
  props: ['item'],
  components: {
    TreemapSimple
  },
  computed: {
  },
  created: function () {
  },
  data () {
    const data = {
      filter: ''
    }
    return data
  },
  methods: {
  }
}
</script>
